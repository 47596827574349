<template>
  <header
    v-show="!$route.meta.hideHeader"
    class="header grid grid-3 grid-s-2"
  >
    <router-link
      to="/"
      class="logo"
    >
      <img
        :src="require('@/assets/logo.svg')"
      >
    </router-link>
    <div
      class="caption"
      v-html="currentCaption.main"
    />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapGetters(['currentCaption']),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.header {
  .logo {
    width: auto;
    height: 21px;
    transform: translateY(-3px);

    &.router-link-exact-active {
      pointer-events: none;
    }
    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
  .caption {
    grid-column-end: span 3;
    @include mq(s) {
      grid-column-end: span 5;
    }
  }
  ::v-deep a {
    pointer-events: auto;
  }
}
</style>
