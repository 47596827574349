<template>
  <div :class="['gallery-wrapper', themeClass]">
    <div
      v-if="theme === 'news' && galleryLength > 1"
      ref="navGallery"
      class="nav-gallery"
    >
      <div
        v-for="(item, id) in data.value.gallery"
        :key="item.id"
        :class="['item', {'is-selected': gallery && gallery.selectedIndex === id}]"
        @click="setItem(id)"
      >
        <img
          :src="item.sizes.s"
          alt=""
        >
      </div>
    </div>

    <div
      ref="gallery"
      :key="isMobile ? 'mobile' : 'desktop'"
      class="gallery"
    >
      <div
        v-for="(item) in galleryItems"
        :key="`${isMobile ? 'mobile' : 'desktop'}-${item.id}`"
        class="item"
      >
        <Figure
          :data="{
            value: {
              image: item,
            },
            settings: {},
          }"
          :lazyload="false"
          :theme="theme"
          disable-ratio
        />
      </div>
    </div>
    <div
      v-if="theme === 'news'"
      class="caption"
      v-html="currentCaption.news"
    />
    <div
      class="prev"
      @click="prev"
    />
    <div
      class="next"
      @click="next"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import 'flickity-imagesloaded';
import 'flickity-fade';

import Figure from '@/components/media/figure';

const flickitySettings = {
  common: {
    cellAlign: 'left',
    cellSelector: '.item',
    imagesLoaded: true,
    selectedAttraction: 0.5,
    friction: 1,
    pageDots: false,
    wrapAround: true,
  },
  full: {
    fade: true,
    prevNextButtons: false,
    pauseAutoPlayOnHover: false,
    draggable: false,
    autoPlay: 5000,
  },
  news: {
    setGallerySize: false,
    prevNextButtons: false,
  },
};

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: () => {},
    },
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gallery: null,
      captions: [],
      settings: { ...flickitySettings.common, ...flickitySettings[this.theme] },
    };
  },
  computed: {
    ...mapGetters(['currentCaption']),
    themeClass() {
      return this.theme ? `gallery--${this.theme}` : false;
    },
    galleryLength() {
      return this.data.value?.gallery?.length || 0;
    },
    isMobile() {
      return this.$mq.isMobileS;
    },
    galleryItems() {
      let items = this.data.value?.gallery || [];
      if (this.theme === 'full') {
        if (this.isMobile) {
          items = this.data.mobile || items;
        } else {
          items = this.data.desktop || items;
        }
      }
      return items;
    },
  },
  watch: {
    data() {
      this.refreshFlickity();
    },
    galleryItems() {
      this.refreshFlickity();
    },
  },
  mounted() {
    this.captions = (this.galleryItems.map((image) => image.caption)) || [];
    this.updateCaption(0);
    this.flickityInit();
  },
  beforeUnmount() {
    this.flickityDestroy();
  },
  methods: {
    updateCaption(id) {
      const newCaption = id < this.captions.length ? this.captions[id] : '';
      this.$store.commit('SET_CURRENTCAPTION', { [this.uid]: newCaption });
    },
    setItem(id) {
      this.gallery.select(id, true, false);
    },
    prev() {
      this.gallery.stopPlayer();
      this.gallery.previous();
      if (this.gallery.options.autoPlay) {
        this.gallery.playPlayer();
      }
    },
    next() {
      this.gallery.stopPlayer();
      this.gallery.next();
      if (this.gallery.options.autoPlay) {
        this.gallery.playPlayer();
      }
    },
    flickityInit() {
      this.gallery = new Flickity(
        this.$refs.gallery,
        { ...this.settings, ...this.extraSettings },
      );
      this.gallery.on('change', this.updateCaption);
    },
    flickityDestroy() {
      this.gallery.off('change', this.updateCaption);
      this.gallery.destroy();
    },
    refreshFlickity() {
      this.flickityDestroy();
      this.$nextTick(() => { this.flickityInit(); });
      this.captions = (this.galleryItems.map((image) => image.caption)) || [];
      this.updateCaption(0);
    },
  },
};
</script>

<style lang="scss">
.gallery-wrapper {
  .nav-gallery {
    width: 40.5px;
    height: 27px;
    // margin-bottom: var(--spacer-s);
    // overflow: hidden;
    white-space: nowrap;

    .item {
      display: inline-block;
      // width: auto;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      @include aspect-ratio(3, 2);
      cursor: pointer;
      touch-action: manipulation;

      & > * {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &.is-selected {
        opacity: 1;
      }
    }
  }
  .gallery {
    overflow: hidden;

    .flickity-viewport {
      overflow: visible;
    }
    .item {
      width: 100%;
    }
  }
}

.gallery {
  &--full {
    position: relative;

    .prev,
    .next {
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      cursor: pointer;
      user-select: none;
      touch-action: manipulation;
    }
    .prev {
      left: 0;
    }
    .next {
      right: 0;
    }
    .gallery {
      .flickity-viewport {
        pointer-events: none;
      }
    }
  }
  &--news {
    position: absolute;
    bottom: var(--spacer-m);
    left: var(--spacer-m);
    right: var(--spacer-m);
    @include mq(s) {
      bottom: var(--spacer-m);
    }

    .gallery {
      @include aspect-ratio(3, 2);
      width: 100%;
      height: 100%;
      .item {
        width: 100%;
        height: 100%;
      }
    }
    .caption {
      margin-top: var(--spacer-s);
      @include mq(s) {
        display: none;
      }
    }
  }
}
</style>
