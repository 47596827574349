<template>
  <div
    class="video-player"
  >
    <div
      v-if="data.settings.source === 'url'"
      ref="player"
      class="plyr"
      v-html="data.value.url"
    />

    <video
      v-if="data.settings.source === 'file'"
      ref="player"
      class="plyr"
      :data-poster="data.settings.poster && data.value.poster ? data.value.poster.sizes.l : null"
      playsinline
    >
      <source
        :src="data.value.src.url"
        type="video/mp4"
      >
    </video>

    <Richtext
      v-if="data.value.caption"
      :data="{ innerHTML: data.value.caption }"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Richtext from '@/components/typo/richtext';

export default {
  name: 'VideoPlayer',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
    };
  },
  computed: {
    embed() {
      return false;
    },
  },
  mounted() {
    this.plyr = new Plyr(this.$refs.player, {
      autoplay: false,
      controls: ['play', 'progress', 'mute'],
      fullscreen: false,
      storage: {
        enabled: false,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: $color-white;
  --plyr-range-track-height: 2px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type="range"] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
}
</style>
