<template>
  <div id="app">
    <Gallery
      v-if="options.main_gallery"
      id="bg-gallery"
      :data="options.main_gallery"
      theme="full"
      uid="main"
    />
    <div class="top grid grid-12 grid-s-6 column-gap-m spacer--padding--a--m">
      <AppHeader />
      <AppFooter />
    </div>

    <div class="bottom grid grid-6 column-gap-m spacer--padding--a--m">
      <div
        class="news grid grid-12 grid-s-2"
        :class="{
          active: panel.news,
          hidden: $mq.isMobileS && panel.about && !panel.news,
        }"
        @click="togglePanel('news')"
      >
        <span v-if="!panel.news">News</span>
        <!-- <span v-if="!panel.news">News ({{ newsCount }})</span> -->
        <!-- <span
          v-else
          class="close'"
        >Close</span> -->
      </div>
      <div
        class="about grid grid-12 grid-s-2"
        :class="{
          active: panel.about,
          hidden: $mq.isMobileS && panel.news && !panel.about,
        }"
        @click="togglePanel('about')"
      >
        <span v-if="!panel.about">About</span>
        <!-- <span
          v-else
          class="close"
        >Close</span> -->
      </div>
    </div>

    <transition
      name="fade"
      @enter="enter"
      @leave="leave"
    >
      <News
        v-if="panel.news"
        @close="closePanel('news')"
      />
    </transition>

    <transition
      name="fade"
      @enter="enter"
      @leave="leave"
    >
      <About
        v-if="panel.about"
        @close="closePanel('about')"
      />
    </transition>

    <div class="sub">
      <transition
        name="fade"
        @enter="enter"
        @leave="leave"
      >
        <router-view :key="$route.path" />
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import Gallery from '@/components/media/gallery';

import About from '@/views/About.vue';
import News from '@/views/News.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Gallery,
    About,
    News,
  },
  mixins: [lazyload],
  data() {
    return {
      panel: {
        news: false,
        about: false,
      },
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'newsCount', 'transition']),
  },
  created() {
    medusa.init();
  },
  mounted() {
    // set panels from url, and clean url
    const panels = this.$route.hash.slice(1).split('-'); // remove hash (#) then split
    const newHash = [];
    panels.forEach((name) => {
      if (typeof this.panel[name] === 'boolean') {
        this.panel[name] = true;
        newHash.push(name);
      }
    });
    this.$router.replace({ hash: newHash.join('-') });

    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );

    window.addEventListener('orientationchange', () => {
      window.requestAnimationFrame(() => {
        this.$mq.vh();
      });
    });
  },
  methods: {
    enter() {
      this.$store.commit('SET_TRANSITION', false);
    },
    leave() {
      this.$store.commit('SET_TRANSITION', true);
    },
    togglePanel(name) {
      if (typeof this.panel[name] === 'boolean') {
        this.panel[name] = !this.panel[name];
        const hash = Object.entries(this.panel)
          .filter(([, val]) => val)
          .map(([key]) => key)
          .join('-');
        this.$router.replace({ hash });
      }
    },
    closePanel(name) {
      if (typeof this.panel[name] === 'boolean') {
        this.panel[name] = false;
        const hash = Object.entries(this.panel)
          .filter(([, val]) => val)
          .map(([key]) => key)
          .join('-');
        this.$router.replace({ hash });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.app-loaded {
  #loader {
    display: none;
  }
}

#app {
  overscroll-behavior-y: none;
}

.sub {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh) - var(--spacer-m));
  overflow: hidden;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}

// #bg-gallery {
//   position: fixed;
//   left: 0;
//   width: 100%;
// }

.top,
.bottom {
  position: fixed;
  left: 0;
  width: 100%;
  color: var(--white);
}

.top {
  top: 0;

  & > * {
    padding-top: var(--spacer-s);
    border-top: 4px solid var(--white);
  }
  & > *:not(:first-child) {
    @include mq($until: s) {
      padding-top: 0;
      border-top: none;
    }
  }
}
.bottom {
  bottom: 0;
  z-index: 100;
  pointer-events: none;

  & > * {
    pointer-events: auto;
    user-select: none;
    cursor: pointer;
  }
  .news,
  .about {
    overscroll-behavior-y: none;
    border-bottom: 4px solid var(--white);
    & > * {
      padding-top: 2px;
      padding-bottom: 5px;
    }
    @include mq($and: $hover) {
      &:hover {
        // border-color: var(--black);
        & > * {
          // background: var(--black);
        }
      }
    }
  }
  & > * {
    // transition: all 0.1s ease-in-out;
    opacity: 1;
    visibility: visible;
  }
  .hidden {
    @include mq($until: s) {
      transition: none;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.news,
.about {
  .router-link-active &,
  &.active {
    visibility: hidden;
    // border-color: var(--black);
    & > * {
      // background: var(--black);
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.slide-enter-active {
  animation: slide-in 0.2s ease-in-out;
}
.slide-leave-active {
  animation: slide-in 0.2s ease-in-out reverse;
}
@keyframes slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.fade-enter-active {
  animation: fade-in 50ms ease-in-out;
}
.fade-leave-active {
  animation: fade-in 50ms ease-in-out reverse;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
