<template>
  <main class="grid grid-12 grid-s-6">
    <div
      v-if="$route.params.slug === 'about'"
      class="about-wrapper"
    >
      <div class="about">
        <div
          v-for="section in sections"
          :key="section.title1"
          class="section grid grid-12 row-gap-0"
          :class="{ 'grid-s-6': section.twoColsLayout }"
        >
          <div class="first">
            <div
              class="title-1"
              v-html="section.title1"
            />
            <div
              class="content-1"
              v-html="section.content1"
            />
          </div>
          <div
            v-if="section.twoColsLayout"
            class="second"
          >
            <!-- eslint-disable -->
            <div
              :class="['title-2', { empty: !section.title2 }]"
              v-html="section.title2 || ' '"
            />
            <!-- eslint-enable -->
            <div
              class="content-2"
              v-html="section.content2"
            />
          </div>
        </div>
        <div class="foot grid grid-6 grid-s-3">
          <div
            class="first"
            v-html="options.footer.about.first"
          />
          <div
            class="second"
            v-html="options.footer.about.second"
          />
          <div
            class="third"
            v-html="options.footer.about.third"
          />
          <div
            class="fourth"
            v-html="options.footer.about.fourth"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import data from '@/mixins/data';

export default {
  name: 'Page',
  mixins: [data],
  data() {
    return {
      sections: null,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },

  mounted() {
    const acfSections = this.post.gds_blocks.filter(
      (block) => block.blockName === 'acf/section',
    );
    this.sections = acfSections.map(
      ({
        attrs: {
          data: { value: section },
        },
      }) => ({
        title1: section.title1,
        title2: section.title2,
        content1: section.content1,
        content2: section.content2,
        twoColsLayout: !!section.content2,
      }),
    );
  },
};
</script>
<style lang="scss" scoped>
main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh));
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}

.about-wrapper {
  position: relative;
  background: var(--white);
  margin: var(--spacer-m);
  grid-column-end: -1;
  // border-bottom: 4px solid var(--black);
  @include mq(s) {
    margin-left: 0;
  }

  .about {
    position: absolute;
    width: 100%;
    max-height: 100%;
    border-top: 4px solid var(--black);
    overflow: auto;

    & > *:first-child {
      .title-1,
      .title-2 {
        border-top: none;
      }
    }
  }
}

.section {
  margin-bottom: var(--spacer-m);
  .title-1,
  .title-2 {
    border-top: 4px solid var(--black);
    padding-top: var(--spacer-s);
    margin-bottom: var(--spacer-l);
  }
  .content-1,
  .content-2 {
    ::v-deep p {
      margin: var(--spacer-l) 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  .second .title-2.empty {
    @include mq($until: s) {
      display: none;
    }
  }
}

.foot {
  // margin-bottom: var(--spacer-xl);
  & > * {
    // margin-bottom: var(--spacer-xl);
    padding-top: var(--spacer-s);
    // border-top: 4px solid var(--black);
  }
}

.close {
  position: absolute;
  width: 100%;
  bottom: 0;
  color: var(--white);
  // border-bottom: 4px solid var(--black);

  .btn {
    display: inline-block;
    background: var(--black);
    color: var(--white);
    padding-bottom: var(--spacer-s);
    @include mq($until: s) {
      grid-column: span 2 / -1;
    }
  }
}
</style>
