<template>
  <footer
    class="page-footer grid grid-3 grid-s-2"
  >
    <div class="email-phone grid grid-12 row-gap-0">
      <div
        v-if="options.footer.info.length > 0"
        v-html="options.footer.info[0].content"
      />
    </div>
    <div class="address grid grid-12">
      <div
        v-if="options.footer.info.length > 1"
        v-html="options.footer.info[1].content"
      />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['options', 'currentPost']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  // font-size: 12px;
  // font-weight: 700;
  color: var(--white);

}
.page-footer {
  .email-phone {
    grid-column-end: span 2;
  }
  .address {
    grid-column-end: span 4;
  }
  .email-phone,
  .address {
    @include mq($until: s) {
      grid-column: 2 / span 3;
    }
  }
}
</style>
