<template>
  <main class="news--content grid grid-12 grid-s-6">
    <div class="news-wrapper">
      <div class="news grid grid-3 grid-s-2 row-gap-s">
        <div
          class="date"
          v-html="date"
        />
        <!-- <div class="prev-next">
          <span
            class="prev"
            @click="previous"
          >Prev</span>&emsp;/&emsp;<span
            class="next"
            @click="next"
          >Next</span>
        </div> -->
        <!-- <div class="progress">
          {{ allNews.length > 0 ? selectedNews + 1 : 0 }} of {{ allNews.length }}
        </div> -->
        <div
          class="title"
          v-html="news.data && news.data.title"
        />
        <div
          class="close"
          @click="$emit('close')"
        >
          Close
        </div>
      </div>
      <!-- <Gallery
        v-if="news.data && news.data.gallery"
        ref="gallery"
        :data="news.data.gallery"
        theme="news"
        uid="news"
      /> -->
      <div
        v-if="news.data && news.data.content"
        class="news--paragraph"
        v-html="news.data.content"
      />
      <div
        v-if="(news.data && news.data.gallery)"
        ref="gallery"
        class="news--gallery"
        uid="news"
      >
        <div
          v-for="(item, id) in news.data.gallery.value.gallery"
          :key="id"
          class="news--gallery--item"
        >
          <img
            :src="item.sizes.s"
            alt=""
          >
        </div>
      </div>
      <!-- <div class="foot grid grid-3 grid-s-2">
        <div
          class="caption"
          v-html="currentCaption.news"
        />
        <div
          class="prev"
          @click="previous"
        >
          Prev
        </div>
        <div
          class="next"
          @click="next"
        >
          Next
        </div>
      </div> -->
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
// import Gallery from '@/components/media/gallery';

export default {
  name: 'News',
  components: {
    // Gallery,
  },
  data() {
    return {
      allNews: [],
      selectedNews: 0,
    };
  },
  computed: {
    ...mapGetters(['currentCaption']),
    news() {
      const id = this.selectedNews;
      return id < this.allNews.length ? this.allNews[id] : {};
    },
    date() {
      const [date] = this.news?.date?.split('T') || [undefined];
      return date?.split('-').reverse().join(' ') || '';
    },
  },
  mounted() {
    const array = Object.entries(this.$store.state.wp.posts.default);
    array.sort((a, b) => (a[1].date > b[1].date ? -1 : 1));
    this.allNews = array.map(([, item]) => ({
      date: item.date,
      data: item.gds_blocks.find(({ blockName }) => blockName === 'acf/news')
        ?.attrs.data.value,
    }));
    // this.$nextTick(this.height2gallery)
    // this.$bus.$on('windowResized', this.height2gallery)
  },
  beforeDestroy() {
    // this.$bus.$off('windowResized', this.height2gallery)
  },
  methods: {
    next() {
      const nextID = this.selectedNews + 1;
      this.selectedNews = nextID < this.allNews.length ? nextID : 0;
    },
    previous() {
      const previousID = this.selectedNews - 1;
      this.selectedNews = previousID >= 0
        ? previousID
        : this.allNews.length > 0
          ? this.allNews.length - 1
          : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100 * var(--vh));
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}

.news-wrapper {
  position: relative;
  background: var(--white);
  margin: var(--spacer-m);
  padding: var(--spacer-m);
  max-height: 100%;
  overflow: auto;
  // border-top: 4px solid var(--black);
  // border-bottom: 4px solid var(--black);
  @include mq(s) {
    margin-right: 0;
  }

  .news {
    width: 100%;
    max-height: 100%;
    // margin-top: var(--spacer-s);
    overflow: auto;
    &--paragraph {
      padding-top: var(--spacer-l);
    }
    &--gallery {
      &--item {
        // &:not(:first-child) {
          padding-top: var(--spacer-m);
        // }
        img {
          display: block;
          width: 100%;
        }
      }
    }

    .prev-next {
      grid-column: 2 / span 4;
      user-select: none;
      @include mq($until: s) {
        display: none;
      }
      .prev,
      .next {
        cursor: pointer;
      }
    }
    .progress {
      grid-column-end: -1;
    }
    .title {
      grid-column: 2 / span 2;
      // padding-top: var(--spacer-l);
      @include mq(s) {
        grid-column: 2 / span 3;
      }
    }

    .close {
      justify-self: right;
      cursor: pointer;
      grid-column: span 1 / -1;
    }
  }
}

.foot {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: 5px;

  .caption {
    grid-column: 2 / span 5;
    @include mq($until: s) {
      display: none;
    }
  }
  .prev {
    grid-column-start: 3;
  }
  .prev,
  .next {
    cursor: pointer;
    user-select: none;
    @include mq(s) {
      display: none;
    }
  }
}
</style>
