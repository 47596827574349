var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.options.main_gallery)?_c('Gallery',{attrs:{"id":"bg-gallery","data":_vm.options.main_gallery,"theme":"full","uid":"main"}}):_vm._e(),_c('div',{staticClass:"top grid grid-12 grid-s-6 column-gap-m spacer--padding--a--m"},[_c('AppHeader'),_c('AppFooter')],1),_c('div',{staticClass:"bottom grid grid-6 column-gap-m spacer--padding--a--m"},[_c('div',{staticClass:"news grid grid-12 grid-s-2",class:{
        active: _vm.panel.news,
        hidden: _vm.$mq.isMobileS && _vm.panel.about && !_vm.panel.news,
      },on:{"click":function($event){return _vm.togglePanel('news')}}},[(!_vm.panel.news)?_c('span',[_vm._v("News")]):_vm._e()]),_c('div',{staticClass:"about grid grid-12 grid-s-2",class:{
        active: _vm.panel.about,
        hidden: _vm.$mq.isMobileS && _vm.panel.news && !_vm.panel.about,
      },on:{"click":function($event){return _vm.togglePanel('about')}}},[(!_vm.panel.about)?_c('span',[_vm._v("About")]):_vm._e()])]),_c('transition',{attrs:{"name":"fade"},on:{"enter":_vm.enter,"leave":_vm.leave}},[(_vm.panel.news)?_c('News',{on:{"close":function($event){return _vm.closePanel('news')}}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"},on:{"enter":_vm.enter,"leave":_vm.leave}},[(_vm.panel.about)?_c('About',{on:{"close":function($event){return _vm.closePanel('about')}}}):_vm._e()],1),_c('div',{staticClass:"sub"},[_c('transition',{attrs:{"name":"fade"},on:{"enter":_vm.enter,"leave":_vm.leave}},[_c('router-view',{key:_vm.$route.path})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }